import React, { useState } from "react";
import { FiShoppingCart } from "react-icons/fi";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../State/Cart/cartSlice";
import useProducts from "../utils/useProducts";
import { filters } from "../data/FilterData";
import { useFilters } from "../utils/useFilters";
import "../components/Product/Product.css";
import Pagination from "../components/Product/Pagination";
import ProductFilterComponent from "../components/Product/ProductFilterComponent";

const ProductList = () => {
  const { category } = useParams(); // Get category from the route params
  const { colorValues, sortBy } = useFilters();
  const { products, totalPages, setPage, currentPage } = useProducts(category, {
    colorValues,
    sortBy,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ProductCard = ({ product }) => {
    const [selectedColor, setSelectedColor] = useState(product.colors[0]);
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const handleAddItemToCart = () => {
      const item = {
        productId: product.id,
        title: product.title,
        color: product.colors[selectedColorIndex],
        imageUrl: product.colors[selectedColorIndex]?.images[0]?.imageUrl,
        unitPrice: product.price,
        quantity: 1,
      };

      dispatch(addItemToCart(item));
      navigate("/cart");
    };

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-white rounded-lg shadow-sm overflow-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div className="relative aspect-w-3 aspect-h-4">
          <img
            onClick={() => navigate(`/product/${product.id}`)}
            src={product?.colors[selectedColorIndex]?.images[0].imageUrl}
            alt={product.title}
            className="object-cover w-full h-full transform transition-transform duration-300"
            style={{ transform: isHovered ? "scale(1.05)" : "scale(1)" }}
          />
          {(new Date() - new Date(product.createdAt)) / (1000 * 60 * 60 * 24) <=
            30 && (
            <span className="absolute top-2 right-2 bg-black text-white px-2 py-1 text-xs rounded">
              New
            </span>
          )}

          <div className="absolute bottom-2 right-2 space-y-2">
            <button
              onClick={handleAddItemToCart}
              className="bg-black text-white p-2 rounded-full shadow-md hover:bg-gray-800">
              <FiShoppingCart className="w-5 h-5" />
            </button>
          </div>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-medium">{product.title}</h3>
          <div className="flex items-center mt-2">
            {product.discount ? (
              <>
                <span className="text-gray-900 font-semibold font-moneta">
                  € {product.discount.toFixed(2)}
                </span>
                <span className="ml-2 text-gray-500 line-through text-sm">
                  € {product.price?.toFixed(2) ?? "0.00"} EUR
                </span>
              </>
            ) : (
              <span className="text-gray-900 font-semibold font-moneta">
                € {product.price?.toFixed(2) ?? "0.00"}
              </span>
            )}
          </div>
          <div className="mt-3 flex space-x-2">
            {product.colors.map((color, index) => (
              <button
                key={index}
                onClick={() => {
                  setSelectedColor(color);
                  setSelectedColorIndex(index);
                }}
                className={`w-6 h-6 rounded-full border-2 ${
                  selectedColor.name === color.name
                    ? "border-black"
                    : "border-gray-300"
                }`}
                style={{ backgroundColor: color.name.toLowerCase() }}
              />
            ))}
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="min-h-screen">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          <ProductFilterComponent filters={filters} />

          <div className="flex-1 space-y-6">
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {products && products.length > 0 ? (
                products.map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))
              ) : (
                <div className="text-center text-gray-500 text-lg mt-4">
                  No Products Found
                </div>
              )}
            </div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
