import { BsBarChart, BsBox, BsCart3, BsChat, BsGear, BsGrid1X2Fill, BsInbox, BsMegaphone, BsPeople, BsShieldLock, BsWallet2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const AdminSidebar = () => {
    const navigate = useNavigate();
  const menuItems = [
    { icon: BsGrid1X2Fill, label: "Dashboard", badge: null, path: "/admin" },
    { icon: BsBox, label: "Products", badge: "New", path: "/admin/products" },
    { icon: BsCart3, label: "Orders", badge: "12", path: "/admin/orders" },
    { icon: BsPeople, label: "Customers", badge: null, path: "/admin/customers" },
    { icon: BsWallet2, label: "Transactions", badge: null, path: "/admin" },
    { icon: BsBarChart, label: "Analytics", badge: null, path: "/admin" },
    { icon: BsInbox, label: "Inventory", badge: "3", path: "/admin" },
    { icon: BsMegaphone, label: "Marketing", badge: null, path: "/admin" },
    { icon: BsChat, label: "Support", badge: "5", path: "/admin" },
    { icon: BsShieldLock, label: "Security", badge: null, path: "/admin" },
    { icon: BsGear, label: "Settings", badge: null, path: "/admin" }
  ];

  return (
    <div className="sticky w-20 lg:w-64 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 transition-all duration-300 ease-in-out">
      <div className="p-6 text-center">
        <h1 className="text-xl font-bold text-gray-800 dark:text-white hidden lg:block">Admin Panel</h1>
        <img 
          src="https://images.unsplash.com/photo-1515886657613-9f3515b0c78f" 
          alt="Logo" 
          className="w-12 h-12 mx-auto rounded-full border-2 border-blue-500"
        />
      </div>
      <nav className="mt-8">
        {menuItems.map((item, index) => (
          <div 
            key={index}
            onClick={() => navigate(item.path)}
            className="flex items-center px-6 py-3 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors relative group"
          >
            <item.icon className="w-5 h-5 text-gray-600 dark:text-gray-400" />
            <span className="ml-4 hidden lg:block text-gray-700 dark:text-gray-300">{item.label}</span>
            {item.badge && (
              <span className="absolute right-4 bg-red-500 text-white text-xs px-2 py-1 rounded-full hidden lg:block">
                {item.badge}
              </span>
            )}
            <div className="absolute left-0 h-full w-1 bg-blue-500 scale-y-0 group-hover:scale-y-100 transition-transform" />
          </div>
        ))}
      </nav>
    </div>
  );
};

export default AdminSidebar