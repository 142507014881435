import { useState, useCallback } from "react";
import { HiChevronLeft, HiChevronRight, HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";

const Pagination = ({
  totalPages = 10,
  onPageChange,
  currentPage,
  setPage,
  maxVisiblePages = 5,
}) => {

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setPage(newPage);
        onPageChange?.(newPage);
      }
    },
    [totalPages, onPageChange]
  );

  const renderPageNumbers = () => {
    const pages = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pages.push(
        <button
          key="ellipsis-start"
          className="px-3 py-2 rounded-md text-gray-500"
          aria-label="More pages"
          disabled
        >
          ...
        </button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(--i)}
          className={`px-4 py-4 transition-all duration-200 ${++currentPage === i
            ? "text-blue-600 border-t-2 border-t-blue-600"
            : "text-gray-700 border-t-[1px] hover:border-t-gray-400"}`}
          aria-label={`Page ${i}`}
          aria-current={currentPage === i ? "page" : undefined}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <button
          key="ellipsis-end"
          className="px-3 py-2 rounded-md text-gray-500"
          aria-label="More pages"
          disabled
        >
          ...
        </button>
      );
    }

    return pages;
  };

  const navigationButton = (icon, label, onClick, disabled) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`p-2 rounded-md transition-all duration-200 ${disabled
        ? "text-gray-300 cursor-not-allowed"
        : "text-gray-700 hover:bg-gray-100 active:bg-gray-200"}
        focus:outline-none focus:border-t-2 focus:border-t-blue-500 focus:ring-opacity-50`}
      aria-label={label}
    >
      {icon}
    </button>
  );

  return (
    <nav
      className="flex items-center justify-between border-t-[1px] border-gray-300 gap-2 px-4 sm:px-6 bg-white shadow-sm w-full"
      aria-label="Pagination"
      role="navigation"
    >
      <div className="flex items-center gap-1">
        {navigationButton(
          <HiChevronDoubleLeft className="w-5 h-5" />,
          "First page",
          () => handlePageChange(0),
          currentPage === 1
        )}
        {navigationButton(
          <HiChevronLeft className="w-5 h-5" />,
          "Previous page",
          () => handlePageChange(currentPage - 1),
          currentPage === 1
        )}
      </div>

      <div className="flex items-center justify-center gap-1 flex-grow">{renderPageNumbers()}</div>

      <div className="flex items-center gap-1">
        {navigationButton(
          <HiChevronRight className="w-5 h-5" />,
          "Next page",
          () => handlePageChange(currentPage + 1),
          currentPage === totalPages
        )}
        {navigationButton(
          <HiChevronDoubleRight className="w-5 h-5" />,
          "Last page",
          () => handlePageChange(totalPages),
          currentPage === totalPages
        )}
      </div>
    </nav>
  );
};

export default Pagination;