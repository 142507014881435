import { useEffect, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { BsBox, BsCart3, BsInbox, BsSearch } from "react-icons/bs";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import EditProductModal from "./EditProductModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductById,
  fetchProducts,
} from "../../../State/Product/productSlice";
import { MdOutlineWarning } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ProductsManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const StatCard = ({ title, value, icon: Icon, color, percentage }) => (
    <div className="bg-white dark:bg-gray-800 p-2 md:p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-500 dark:text-gray-400">{title}</p>
          <h3 className="md:text-2xl font-bold mt-2 dark:text-white">
            {value}
          </h3>
          {percentage && (
            <p
              className={
                percentage > 0
                  ? "text-green-500 text-sm mt-2"
                  : "text-red-500 text-sm mt-2"
              }>
              {percentage > 0 ? "+" : ""}
              {percentage}% from last month
            </p>
          )}
        </div>
        <div className={`p-4 rounded-full ${color} bg-opacity-20`}>
          <Icon
            className={`w-4 h-4 md:w-6 md:h-6 ${color.replace("bg-", "text-")}`}
          />
        </div>
      </div>
    </div>
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortBy, setSortBy] = useState("name");
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const categories = ["all", "Clothing", "Outerwear", "Accessories"];

  const filteredProducts = products
    .filter(
      (product) =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedCategory === "all" || product.category === selectedCategory)
    )
    .sort((a, b) => {
      if (sortBy === "name") return a.title.localeCompare(b.title);
      if (sortBy === "price") return a.price - b.price;
      return 0;
    });

  // Function to handle opening the modal with selected product data
  const handleEditProduct = (product) => {
    setSelectedProduct(product); // Set the product to edit
    setOpenModal(true); // Open the modal
  };

  const handleDeleteProduct = (productId) => {
    setDeleteConfirm(productId);
  };

  const confirmDelete = () => {
    dispatch(deleteProductById(deleteConfirm));
    setDeleteConfirm(null);
  };

  const handleSaveProduct = (updatedProduct) => {
    // Handle saving the updated product data here
    // For example, you can call an API or update your state
    setOpenModal(false); // Close the modal after saving
  };
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="p-8">
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold dark:text-white">
                Products Management
              </h1>
              <p className="text-gray-600 dark:text-gray-400 mt-1">
                Manage and organize your product inventory
              </p>
            </div>
            <button
              onClick={() => navigate("/admin/add-product")}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2">
              <BsBox className="w-4 h-4" />
              Add Product
            </button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
            <StatCard
              title="Total Products"
              value={products.length}
              icon={BsBox}
              color="bg-blue-500"
              percentage={5}
            />
            <StatCard
              title="Low Stock"
              value="2"
              icon={BsCart3}
              color="bg-yellow-500"
              percentage={-2}
            />
            <StatCard
              title="Out of Stock"
              value="1"
              icon={BsInbox}
              color="bg-red-500"
              percentage={0}
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <div className="flex flex-col md:flex-row gap-4 mb-6">
              <div className="flex-1">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search products..."
                    className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600 dark:text-white pl-10"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <BsSearch className="absolute left-3 top-3 text-gray-400" />
                </div>
              </div>
              <div className="flex flex-wrap gap-4 ">
                <select
                  className="px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}>
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </option>
                  ))}
                </select>
                <select
                  className="px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}>
                  <option value="name">Sort by Name</option>
                  <option value="price">Sort by Price</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-white dark:bg-gray-800">
                  <tr>
                    {[
                      "",
                      "ID",
                      "Title",
                      "Price",
                      "Category",
                      "Colors",
                      "Actions",
                    ].map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                        <div className="flex items-center gap-2">
                          {header}
                          <BiSortAlt2 />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-600 text-gray-400">
                  {filteredProducts.map((product) => (
                    <tr
                      key={product.id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-1">
                          <img
                            className="w-16 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 rounded-full object-cover border border-gray-200"
                            src={product?.colors[0].images[0].imageUrl}
                            alt="Product Color"
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {product.id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {product.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        ${product.price}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {product.categoryName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-1">
                          {product.colors.map((color, index) => (
                            <div
                              key={index}
                              className={`w-6 h-6 rounded-full ${color.className} border border-gray-200`}
                              title={color.name}
                            />
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleEditProduct(product)}
                            className="text-blue-600 hover:text-blue-800">
                            <FiEdit2 />
                          </button>
                          <button
                            onClick={() => handleDeleteProduct(product.id)}
                            className="text-red-600 hover:text-red-800">
                            <FiTrash2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {selectedProduct && (
              <EditProductModal
                open={openModal}
                onClose={() => setOpenModal(false)} // Close modal
                product={selectedProduct}
                onSave={handleSaveProduct} // Handle save function
              />
            )}
            {deleteConfirm && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg p-6 max-w-md w-full">
                  <div className="flex items-center gap-3 text-red-600 mb-4">
                    <MdOutlineWarning className="text-2xl" />
                    <h2 className="text-xl font-bold">Confirm Deletion</h2>
                  </div>
                  <p className="text-gray-600 mb-6">
                    Are you sure you want to delete this product? This action
                    cannot be undone.
                  </p>
                  <div className="flex gap-4 justify-end">
                    <button
                      onClick={confirmDelete}
                      className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition-colors">
                      Delete
                    </button>
                    <button
                      onClick={() => setDeleteConfirm(null)}
                      className="px-4 py-2 border rounded-lg hover:bg-gray-50">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-6 flex justify-between items-center">
              <p className="text-gray-600 dark:text-gray-400">
                Showing {filteredProducts.length} of {products.length} products
              </p>
              <div className="flex gap-2">
                <button className="px-4 py-2 border rounded-lg dark:border-gray-600 dark:text-white">
                  Previous
                </button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsManagement;
