import React, { useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import { Divider } from "@mui/material";
import { useInView } from "react-intersection-observer";
import SectionProductCard from "./SectionProductCard";
import "react-alice-carousel/lib/alice-carousel.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../State/Product/productSlice";

const SectionCarousel = ({ categoryName, sectionName}) => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  useEffect(() => {
    if(categoryName && products.length === 0){
      const params = new URLSearchParams({
        categoryName,
      });
      dispatch(fetchProducts(params));
    }
  }, [categoryName, products, dispatch]);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  const responsive = {
    0: { items: 2.2 }, // Mobile
    768: { items: 2.4 }, // Tablet
    1024: { items: 4.4 }, // Small Desktop
    1440: { items: 5.3 }, // Large Desktop
    2000: { items: 6.5 },
  };
  const items = products.map((product) => <SectionProductCard product={product} />);

    // Automatically set section name if not provided
  return (
    <div
      ref={ref}
      className={`${
        inView
          ? "animate-fade-in" // Apply animation when in view
          : "opacity-0" // Set initial opacity to 0 when not in view
      }`}
    >
      <Divider
        variant="fullWidth"
        className="font-moneta font-medium text-gray-900 text-26 leading-10 tracking-wider py-8"
      >
        {sectionName}
      </Divider>
      <div className="px-4 pb-6 lg:px-0">
        <AliceCarousel
          items={items}
          responsive={responsive}
          disableDotsControls
          disableButtonsControls
          touchMoveDefaultEvents={false}
        />
      </div>
    </div>
  );
};
export default SectionCarousel;
