import React, { useState, useEffect } from "react";
import { BsGraphUp, BsPeople, BsCart3, BsBell, BsMoon, BsSun, BsWallet2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, AreaChart, Area, PieChart, Pie, Cell } from "recharts";

const salesData = [
  { name: "Jan", sales: 4000, profit: 2400 },
  { name: "Feb", sales: 3000, profit: 1398 },
  { name: "Mar", sales: 5000, profit: 3800 },
  { name: "Apr", sales: 4500, profit: 3908 },
  { name: "May", sales: 6000, profit: 4800 },
  { name: "Jun", sales: 5500, profit: 3800 }
];

const products = [
  {
    id: 1,
    name: "Designer Dress",
    price: 299.99,
    stock: 45,
    sales: 128,
    image: "https://images.unsplash.com/photo-1539008835657-9e8e9680c956",
    status: "active",
    category: "Clothing"
  },
  {
    id: 2,
    name: "Premium Jacket",
    price: 499.99,
    stock: 30,
    sales: 89,
    image: "https://images.unsplash.com/photo-1551028719-00167b16eac5",
    status: "active",
    category: "Outerwear"
  },
  {
    id: 3,
    name: "Leather Handbag",
    price: 199.99,
    stock: 15,
    sales: 67,
    image: "https://images.unsplash.com/photo-1584917865442-de89df76afd3",
    status: "low_stock",
    category: "Accessories"
  }
];

const StatCard = ({ title, value, icon: Icon, color, percentage }) => (
  <div className="bg-white dark:bg-gray-800 p-2 md:p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500 dark:text-gray-400">{title}</p>
        <h3 className="md:text-2xl font-bold mt-2 dark:text-white">{value}</h3>
        {percentage && (
          <p className={percentage > 0 ? "text-green-500 text-sm mt-2" : "text-red-500 text-sm mt-2"}>
            {percentage > 0 ? "+" : ""}{percentage}% from last month
          </p>
        )}
      </div>
      <div className={`p-4 rounded-full ${color} bg-opacity-20`}>
        <Icon className={`w-4 h-4 md:w-6 md:h-6 ${color.replace("bg-", "text-")}`} />
      </div>
    </div>
  </div>
);

const ProductGrid = ({ products }) => (
  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6">
    {products.map(product => (
      <div key={product.id} className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
        <div className="relative">
          <img 
            src={product.image} 
            alt={product.name}
            className="w-full h-36 md:h-48 object-cover"
          />
          <div className="absolute top-2 right-2">
            <span className={`px-2 py-1 rounded-full text-sm ${product.status === "active" ? "bg-green-100 text-green-800" : product.status === "low_stock" ? "bg-yellow-100 text-yellow-800" : "bg-red-100 text-red-800"}`}>
              {product.status}
            </span>
          </div>
        </div>
        <div className="p-2 md:p-4">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-sm md:text-lg md:font-semibold dark:text-white">{product.name}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">{product.category}</p>
            </div>
            <p className="md:text-lg font-semibold md:font-bold text-blue-600 dark:text-blue-400">${product.price}</p>
          </div>
          <div className="mt-4 flex justify-between items-center">
              <span className="text-sm text- text-gray-600 dark:text-gray-300">Sales: {product.sales}</span>
            <button className="px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
              Edit
            </button>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const Overview = () => {
  const [darkMode, setDarkMode] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  return (
    <div className={`flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900 ${darkMode ? "dark" : ""}`}>
      <div className="lg:ml-10 p-8">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-2xl font-bold dark:text-white">Welcome back, Admin</h1>
            <p className="text-gray-600 dark:text-gray-400 mt-1">Here's what's happening with your store today.</p>
          </div>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow"
            >
              {darkMode ? <BsSun className="w-5 h-5 text-yellow-500" /> : <BsMoon className="w-5 h-5 text-gray-600" />}
            </button>
            <div className="relative">
              <button className="p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow">
                <BsBell className="w-5 h-5 dark:text-white" />
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  3
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <StatCard title="Total Sales" value="$24,780" icon={BsGraphUp} color="bg-blue-500" percentage={12} />
          <StatCard title="New Customers" value="120" icon={BsPeople} color="bg-green-500" percentage={8} />
          <StatCard title="Pending Orders" value="35" icon={BsCart3} color="bg-yellow-500" percentage={-5} />
          <StatCard title="Revenue" value="$12,545" icon={BsWallet2} color="bg-purple-500" percentage={15} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold mb-4 dark:text-white">Sales Overview</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={salesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Area type="monotone" dataKey="sales" stroke="#4F46E5" fill="#4F46E5" fillOpacity={0.1} />
                  <Area type="monotone" dataKey="profit" stroke="#10B981" fill="#10B981" fillOpacity={0.1} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold mb-4 dark:text-white">Product Performance</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={products}
                    dataKey="sales"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                  >
                    {products.map((entry, index) => (
                      <Cell key={index} fill={["#4F46E5", "#10B981", "#F59E0B"][index % 3]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 md:p-6 rounded-xl shadow-lg">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold dark:text-white">Recent Products</h2>
            <button onClick={() => navigate("manage-products")} className="px-2 md:px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
              Add Product
            </button>
          </div>
          <ProductGrid products={products} />
        </div>
      </div>
    </div>
  );
};

export default Overview;