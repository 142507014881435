import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useFilters } from "../../utils/useFilters";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useState } from "react";
import { FiFilter } from "react-icons/fi";
const ProductFilterComponent = ({ filters }) => {
  const { colorValues, toggleColor, sortBy, changeSortBy } = useFilters();
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  // Handle filter toggling for color and sorting
  const handleFilter = (value, sectionId) => {
    if (sectionId === "color") {
      toggleColor(value); // Toggle the selected color
    } else if (sectionId === "sort") {
      changeSortBy(value); // Change the sort order
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-bold font-moneta">Women's Collection</h1>
        <button
          onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
          className="lg:hidden flex items-center space-x-2">
          <FiFilter />
          <span>Filters</span>
        </button>
      </div>
      <div
        className={`lg:w-72 space-y-6 ${
          isMobileFilterOpen ? "block" : "hidden"
        }`}>
        {filters.map((section) => (
          <Disclosure
            as="div"
            key={section.id}
            className="border rounded-lg bg-white p-4">
            {({ open }) => (
              <>
                    <Disclosure.Button className="flex w-full items-center justify-between bg-white text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                <Disclosure.Panel className="pt-4 space-y-2">
                  {section.options.map((option, optionIdx) => (
                    <div
                          key={option.value}
                          className="flex items-center checkbox-wrapper-24">
                          <input
                            onChange={() =>
                              handleFilter(option.value, section.id)
                            }
                            type="checkbox"
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            value={option.value}
                            checked={
                              section.id === "color"
                                ? colorValues.includes(option.value)
                                : sortBy === option.value
                            }
                          />
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600">
                            <span></span>
                            {option.label}
                          </label>
                        </div>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
      <div className="w-72">
        <form className="hidden lg:block">
          <div className="flex items-center py-10">
            <h1 className="text-lg opacity-50 font-bold">Filters</h1>
            <FilterListIcon />
          </div>
          {filters.map((section) => (
            <Disclosure
              as="div"
              key={section.id}
              className="border-b border-gray-200 py-6">
              {({ open }) => (
                <>
                  <h3 className="-my-3 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div
                          key={option.value}
                          className="flex items-center checkbox-wrapper-24">
                          <input
                            onChange={() =>
                              handleFilter(option.value, section.id)
                            }
                            type="checkbox"
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            value={option.value}
                            checked={
                              section.id === "color"
                                ? colorValues.includes(option.value)
                                : sortBy === option.value
                            }
                          />
                          <label
                            htmlFor={`filter-${section.id}-${optionIdx}`}
                            className="ml-3 text-sm text-gray-600">
                            <span></span>
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </form>
      </div>
    </div>
  );
};

export default ProductFilterComponent;
