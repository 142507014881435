import { createContext, useContext, useState, useMemo, useEffect } from "react";

const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const searchParams = new URLSearchParams(window.location.search);

  const [colorValues, setColorValues] = useState(() =>searchParams.get("color") ? searchParams.get("color").split(",") : []);
  const [sortBy, setSortBy] = useState(() => searchParams.get("sortBy") || "");

  // Sync filters with URL on load and whenever colorValues or sortBy change
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    // Set colors in the URL
    if (colorValues.length > 0) {
      searchParams.set('color', colorValues.join(',')); // Fix here: using join() instead of split()
    } else {
      searchParams.delete('color');
    }

    // Set sortBy in the URL
    if (sortBy) {
      searchParams.set('sortBy', sortBy);
    } else {
      searchParams.delete('sortBy');
    }

    // Update the URL with new query parameters
    const query = searchParams.toString();
    window.history.replaceState({}, '', `${window.location.pathname}?${query}`);
  }, [colorValues, sortBy]); // Dependency array ensures URL is updated when filters change

  const toggleColor = (color) => {
    setColorValues((prev) =>
      prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
    );
  };

  const changeSortBy = (sortOption) => {
    setSortBy(sortOption);
  };

  // Memoize values to avoid unnecessary renders
  const value = useMemo(
    () => ({ colorValues, sortBy, toggleColor, changeSortBy }),
    [colorValues, sortBy]
  );

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FiltersContext);
};
