import { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, logoutUser } from "../State/Auth/authSlice";
import { getNavigationBasedOnUser } from "../State/Navigation/navigationSlice";
import Cookies from "js-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const [selectedSection, setSelectedSection] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { items } = useSelector((state) => state.cart);
  const { categories, pages } = useSelector((state) => state.navigation);
  const isAuthenticated = Cookies.get("isAuthenticated");

  useEffect(() => {
    dispatch(getNavigationBasedOnUser());
    // Fetch user profile only once if user is not authenticated or a token still exists
    if (isAuthenticated) {
      dispatch(getUserProfile());
    }
  }, [dispatch, isAuthenticated]);

  const handleUserClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };
  const handleCategoryClick = (section, item, close) => {
    navigate(`/${section.path}/${item.path}`);
    close();
  };

  const handleLogout = (e) => {
    dispatch(logoutUser());
    handleCloseUserMenu();
    navigate("/");
  };

  return (
    <div>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs overflow-y-auto flex-col bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <div className="border-b border-gray-200">
                    <Tab.List className="-mb-px flex space-x-8 px-4">
                      {categories?.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-black"
                                : "border-transparent text-gray-900",
                              "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium"
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {categories?.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-10 px-4 pb-8 pt-10 relative"
                      >
                        <div className="grid grid-cols-2 gap-x-4">
                          {category?.subcategories?.featuredItems?.map((item) => (
                            <div
                              key={item.name}
                              className="group relative text-sm"
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.image}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={`/product/${item.id}`}
                                className="mt-6 block font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p aria-hidden="true" className="mt-1">
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div>
                        {category?.subcategories?.sections?.map((section) => (
                          <div key={section.name} className="relative h-full">
                            <details
                              className="relative flex flex-col h-full flex-grow overflow-y-auto"
                              open={selectedSection === section}
                            >
                              <summary
                                className="font-semibold text-base text-gray-900 list-none m-0 p-0"
                                onClick={(e) => {
                                  setSelectedSection(section);
                                  e.preventDefault();
                                }}
                              >
                                {section.name}
                                <svg
                                  viewBox="0 0 14 10"
                                  fill="none"
                                  aria-hidden="true"
                                  focusable="false"
                                  className="absolute right-10 top-[50%] transform translate-y-[-50%] w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </summary>
                              <div
                                className={`fixed top-0 left-0 h-full z-40 w-full p-8 overflow-y-auto bg-white transition-transform ease-linear duration-300 ${
                                  open ? "translate-x-0" : "-translate-x-full"
                                }`}
                                tabIndex="-1"
                                onClick={() => {
                                  setSelectedSection(null);
                                }}
                              >
                                <div className="w-full h-full" tabIndex="-1">
                                  <button
                                    className="mt-6 flex items-center text-lg font-bold font-moneta bg-transparent text-left w-full"
                                    aria-expanded="true"
                                    onClick={() => setSelectedSection(null)}
                                  >
                                    <svg
                                      viewBox="0 0 14 10"
                                      fill="none"
                                      aria-hidden="true"
                                      focusable="false"
                                      className="transform rotate-180 mr-4 w-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                    {section.name}
                                  </button>
                                  <ul>
                                    {section.items.map((item) => (
                                      <li key={item.name} className="mb-2">
                                        <p
                                          className="mt-4 text-lg font-moneta font-semibold"
                                          onClick={() => {
                                            navigate(
                                              `/${category.id}/${section.path}/${item.id}`
                                            );
                                            setSelectedSection(null);
                                            setOpen(false);
                                          }}
                                        >
                                          {item.name}
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </details>
                          </div>
                        ))}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {pages?.map((page) => (
                    <div
                      key={page.pageName}
                      onClick={() => {
                        navigate(`/${page.pagePath}`);
                        setOpen(false);
                      }}
                      className="flow-root -m-2 p-2 font-medium text-gray-900"
                    >
                      {page.pageName}
                    </div>
                  ))}
                </div>

                {user ? (
                  <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                    <div className="flow-root">
                      <a
                        href="/account/orders"
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        My Orders
                      </a>
                    </div>

                    <div
                      className="font-medium text-gray-900 mt-4"
                      onClick={handleLogout}
                    >
                      Logout
                    </div>
                  </div>
                ) : (
                  <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                    <div className="flow-root">
                      <a
                        href="/login"
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        Sign in
                      </a>
                    </div>
                    <div className="flow-root">
                      <a
                        href="/register"
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        Create account
                      </a>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <div className="flex h-10 items-center lg:justify-between justify-center bg-[#eeeeee] px-4 text-sm font-helvetica sm:px-6 lg:px-8">
          <div className="hidden lg:ml-8 lg:flex">
            <a
              href="#"
              className="flex items-center text-gray-700 hover:text-gray-800"
            >
              <img
                src="https://tailwindui.com/img/flags/flag-canada.svg"
                alt=""
                className="block h-auto w-5 flex-shrink-0"
              />
              <span className="ml-3 block text-sm font-medium">EU</span>
              <span className="sr-only">, change currency</span>
            </a>
          </div>
          Get free delivery on orders over $100
          {user?.firstName ? (
            <div className="hidden lg:block">
              <Avatar
                className="text-white"
                onClick={handleUserClick}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                  bgcolor: "black",
                  cursor: "pointer",
                  fontSize: "10px",
                }}
              >
                {user.firstName[0].toUpperCase()}
              </Avatar>
              
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openUserMenu}
                onClose={handleCloseUserMenu}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                <MenuItem onClick={handleCloseUserMenu}>Profile</MenuItem>
                <MenuItem onClick={() => navigate("/account/orders")}>
                  My Orders
                </MenuItem>
                <MenuItem  onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            <div className="hidden lg:flex items-center space-x-6">
              <a
                href="/login"
                className="font-trade hover:underline"
              >
                Sign in
              </a>
              <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
              <a
                href="/register"
                className="font-trade hover:underline"
              >
                Create account
              </a>
            </div>
          )}
        </div>
        <nav
          aria-label="Top"
          className="mx-auto max-w-[120rem] px-4 sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center ">
              <button
                type="button"
                className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-4 lg:block lg:self-stretch z-10">
                <div className="flex h-full space-x-8">
                  {categories?.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ close, open }) => (
                        <>
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "border-b-black"
                                  : "border-transparent text-gray-700",
                                "content-center text-sm font-medium text-gray-700 border-black hover:border-b-[1px] cursor-pointer"
                              )}
                            >
                              {category.name}
                            </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div
                                className="absolute inset-0 top-1/2 bg-white shadow"
                                aria-hidden="true"
                              />

                              <div className="relative bg-white">
                                <div className="mx-auto max-w-7xl px-8">
                                  <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                                    <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                    {category.subcategories.featuredItems && Array.isArray(category.subcategories.featuredItems) ? (
                                      category.subcategories.featuredItems.map((item) => (
                                        <div
                                          key={item.name}
                                          className="group relative text-base sm:text-sm"
                                        >
                                          <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                            <img
                                              src={item.image}
                                              className="object-cover object-center"
                                            />
                                          </div>
                                          <div
                                            onClick={() => {
                                              navigate(`/product/${item.id}`)
                                              close()
                                            }
                                            }
                                            className="mt-6 block font-medium text-gray-900"
                                          >
                                            <span
                                              className="absolute inset-0 z-10"
                                              aria-hidden="true"
                                            />
                                            {item.name}
                                          </div>
                                          <p
                                            aria-hidden="true"
                                            className="mt-1"
                                          >
                                            Shop now
                                          </p>
                                        </div>
                                      ))
                                      ) : (
                                        <div>No items available</div>  // In case section.items is not an array or is empty
                                      )}

                                    </div>
                                    <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm">
                                      {category?.subcategories?.sections?.map((section) => (
                                        <div key={section.name}>
                                          <p
                                            id={`${section.name}-heading`}
                                            className="font-medium text-gray-900"
                                          >
                                            {section.name}
                                          </p>
                                          <ul
                                            role="list"
                                            aria-labelledby={`${section.name}-heading`}
                                            className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                          >
                                            {section.items.map((item) => (
                                              <li
                                                key={item.name}
                                                className="flex"
                                              >
                                                <p
                                                  onClick={() =>
                                                    handleCategoryClick(
                                                      section,
                                                      item,
                                                      close // Here we pass the close function properly
                                                    )
                                                  }
                                                  className="cursor-pointer hover:border-b-[1px] -mb-px border-black"
                                                >
                                                  {item.name}
                                                </p>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {pages?.map((page) => (
                    <div
                      key={page.pageName}
                      onClick={() => navigate(`/${page.pagePath}`)}
                      className="content-center text-sm font-medium text-gray-700 border-black hover:border-b-[1px] cursor-pointer"
                    >
                      {page.pageName}
                    </div>
                  ))}
                </div>
              </Popover.Group>

              {/* Logo */}
              <div className="flex ml-auto ">
                <p className="h-16 flex items-center">
                  <a
                    className="inline-block lg:h-10 xxs:h-8 sm:h-9 opacity-95 w-full"
                    href="/"
                    aria-label="ArgjentinaBajraktari"
                  >
                    <img
                      src={Logo}
                      alt="ArgjentinaBajraktari"
                      className="w-full h-full"
                    />
                  </a>
                </p>
              </div>

              <div className="ml-auto flex items-center">
                {/* Search */}
                <div className="flex lg:ml-6">
                  <a href="#" className="p-2 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </a>
                </div>

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <a href="/cart" className="-m-2 flex items-center">
                    <ShoppingBagIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium text-gray-700 group-hover:text-gray-800">
                      {items.length}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
