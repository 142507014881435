import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Modal,
  Button,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";

const EditProductModal = ({ open, onClose, product, onSave }) => {
  const [selectedTab, setSelectedTab] = useState("productInfo");
  const [productState, setProductState] = useState({
    selectedColor: {},
    colors: product?.colors || {},
    currentImages: [],
  });
  const [selectedCategory, setSelectedCategory] = useState(
    product.categoryName
  );
  const [loading, setLoading] = useState(false);

  const { getRootProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setProductState((prevState) => ({
        ...prevState,
        currentImages: acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      }));
    },
  });

  const formik = useFormik({
    initialValues: {
      title: product?.title || "",
      description: product?.description || "",
      price: product?.price || 0.0,
      category: selectedCategory,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      price: Yup.number()
        .required("Price is required")
        .min(0.01, "Price must be greater than 0"),
      category: Yup.string().required("Category is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const updatedProduct = {
        ...values,
        colors: Object.keys(productState.colors).map((color) => ({
          name: color,
          images: productState.colors[color].images,
        })),
        categoryName: selectedCategory,
      };
      onSave(updatedProduct);
      setLoading(false);
      onClose(); // Close modal after saving
    },
  });

  const handleColorChange = (color) => {
    setProductState((prev) => ({
      ...prev,
      selectedColor: prev.colors[color.name] || color,
    }));
  };

  const handleAddColor = () => {
    const { selectedColor, currentImages } = productState;
    if (!selectedColor || currentImages.length === 0) return;

    // Upload images and update color data
    // (You can implement image upload logic here as per your existing code)
  };

  const handleRemoveImage = (file) => {
    setProductState((prevState) => ({
      ...prevState,
      currentImages: prevState.currentImages.filter(
        (img) => img.name !== file.name
      ),
    }));
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-product-modal">
      <Box className="max-w-4xl mx-auto p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-y-auto max-h-screen">
        <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 justify-self-center">
          Edit Product
        </h2>
        <div className="flex space-x-4 mb-6">
          <button
            className={`px-4 py-2 text-white rounded-md ${
              selectedTab === "productInfo"
                ? "bg-blue-500 "
                : "bg-gray-700 "
            }`}
            onClick={() => setSelectedTab("productInfo")}>
            Product Information
          </button>
          <button
            className={`px-4 py-2 text-white rounded-md ${
              selectedTab === "productColors"
                ? "bg-blue-500 text-white"
                : "bg-gray-700 "
            }`}
            onClick={() => setSelectedTab("productColors")}>
            Product Colors
          </button>
        </div>

        {selectedTab === "productInfo" && (
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <TextField
              label="Title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              variant="outlined"
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
            <TextField
              label="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
            <TextField
              label="Price"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              variant="outlined"
              type="number"
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                label="Category">
                <MenuItem value="bridal">Bridal</MenuItem>
                <MenuItem value="winter">Winter</MenuItem>
                <MenuItem value="evening-dresses">Evening Dresses</MenuItem>
                <MenuItem value="party-dresses">
                  Cocktail & Party Dresses
                </MenuItem>
                <MenuItem value="prom-dresses">Prom Dresses</MenuItem>
                <MenuItem value="homecoming-gowns">Homecoming Gowns</MenuItem>
              </Select>
            </FormControl>
          </form>
        )}

        {selectedTab === "productColors" && (
          <div className="space-y-2">
            <label className="block text-gray-700 font-medium">
              Select Color
            </label>
            <div className="flex space-x-2">
              {/* Color selection buttons */}
            </div>

            <div className="flex items-center space-x-4 mt-4">
              {productState.currentImages.map((file) => (
                <div key={file.name} className="relative">
                  <img
                    src={file.preview}
                    alt={file.name}
                    className="w-20 h-20 object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveImage(file)}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 text-xs flex items-center justify-center">
                    X
                  </button>
                </div>
              ))}
            </div>
            <div {...getRootProps()}>
              <Button
                variant="outlined"
                color="primary"
                className="w-full mt-4">
                Upload Image
              </Button>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddColor}
              className="w-full mt-6">
              Add Color
            </Button>

            {/* Display color images */}
            <label className="block text-gray-700 font-medium">
              Added colors
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 mt-4">
              {product.colors.map((color, index) => (
                <div key={index} className="flex flex-col">
                  {/* Color swatch */}
                  <div
                    className={`w-8 h-8 rounded-full ${color.className} border-4 border-gray-200 cursor-pointer hover:border-gray-400 transition-all`}
                    title={color.name}
                    style={{ backgroundColor: color.hex }}
                  />

                  {/* Display color images */}
                  <div className="grid grid-cols-2 gap-2 mt-2">
                    {color.images.map((image, idx) => (
                      <img
                        key={idx}
                        src={image.imageUrl}
                        className="h-24 scale-90 object-cover w-full rounded-md shadow-md border"
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Modal Footer */}
        <div className="mt-6 flex justify-end space-x-4">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
            disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default EditProductModal;
