import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../pages/admin/AdminSidebar";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
const AdminLayout = () => {
  const token = Cookies.get("token"); // Assuming the token is stored under the name 'token'

  // ✅ Ensure token is a valid string before decoding
  if (!token || typeof token !== "string" || token.trim() === "") {
    return <Navigate to="/admin/login" replace />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const userRole = decodedToken?.role;

    if (userRole === "ADMIN") {
      return (
        <div className="flex h-screen bg-gray-100">
          <AdminSidebar />
          {/* Main content */}
          <div className="flex-1 bg-gray-100 overflow-auto">
            <Outlet />
          </div>
        </div>
      );
    }
  } catch (error) {
    console.error("Invalid token:", error);
    return <Navigate to="/admin/login" replace />;
  }
};

export default AdminLayout;
