import React from 'react';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-[#f8f1e5] font-moneta">
      <div className="text-center p-6 md:p-12 bg-white rounded-lg shadow-xl w-full max-w-lg">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-xl text-gray-600 mb-6">Oops! The page you are looking for doesn't exist.</p>
        <p className="text-sm text-gray-400 mb-6">It seems like you’ve stumbled upon a page that doesn’t exist. Don’t worry, we’ll get you back on track!</p>
        <a 
          href="/" 
          className="inline-block px-6 py-2 bg-primary text-white rounded-md text-lg hover:bg-primary-dark transition"
        >
          Go Back Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
