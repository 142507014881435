import React, { useEffect } from "react";
import { AccountCircle, Lock } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../State/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loading, user } = useSelector((state) => state.auth);
  const isAuthenticated = Cookies.get("isAuthenticated");


  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const user = {
      username: data.get("username"),
      password: data.get("password"),
    };

    dispatch(loginUser(user));
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate("/"); // Navigate to home when user is successfully logged in
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Login - Women’s Fashion Store</title>
        <meta
          name="description"
          content="Log in to your account to manage your orders, preferences, and personal details."
        />
        <meta
          name="keywords"
          content="login, women's fashion, account access, manage orders"
        />
        <meta property="og:title" content="Login - Women’s Fashion Store" />
        <meta
          property="og:description"
          content="Access your account to manage your orders, account settings, and personal information."
        />
        <meta
          property="og:image"
          content="https://argjentinabajraktari.com/images/login-icon.jpg"
        />
        <link rel="canonical" href="https://argjentinabajraktari.com/login" />
      </Helmet>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Box width={400} p={4}>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            fontFamily="moneta"
            fontWeight="500"
            letterSpacing="0.5rem"
          >
            Sign In
          </Typography>
          <form onSubmit={handleSubmit} className="w-full">
            <Box display="flex" flexDirection="column" mb={2}>
              <TextField
                label="Username"
                name="username"
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: <AccountCircle />,
                }}
              />
              <TextField
                label="Password"
                name="password"
                margin="normal"
                variant="outlined"
                type="password"
                InputProps={{
                  startAdornment: <Lock />,
                }}
                InputLabelProps={{
                  shrink: true, // Ensures the label remains above the input
                }}
              />
              {error && (
                <Typography color="error" align="center" gutterBottom>
                  {error}
                </Typography>
              )}
            </Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{
                backgroundColor: "#000",
                borderRadius: "0",
                width: "100%", // Ensure it takes up the available width
              }}
            >
              {loading ? "Logging in..." : "Login"}
            </Button>
          </form>
          <div className="flex py-3">
            <p className="text-sm font-light text-gray-900 py-3">
              Don't have an account?{" "}
              <a
                href="/register"
                className="font-bold font-trade text-primary-600 hover:underline"
              >
                Register
              </a>
            </p>
          </div>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default LoginForm;
