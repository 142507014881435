import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// 🔹 Login
export const getActiveUsers = createAsyncThunk(
  "admin/getActiveUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user/activeUsers`, {
        withCredentials: true, // ✅ Ensures cookies are sent & stored securely
      });
      return response.data; // No need to store JWT manually
    } catch (error) {
      console.log("Error logging in:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// 🔹 Login
export const getAllUsers = createAsyncThunk(
  "admin/getAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user/users`, {
        withCredentials: true, // ✅ Ensures cookies are sent & stored securely
      });
      return response.data; // No need to store JWT manually
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// 🔹 Auth Slice
const adminSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    error: null,
    users: [],
    totalUsers: 0,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // ✅ Login
      .addCase(getActiveUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActiveUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.totalUsers = action.payload;
      })
      .addCase(getActiveUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { resetError } = adminSlice.actions;
export default adminSlice.reducer;
