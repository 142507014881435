import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Auth/authSlice";
import adminSlice from "./admin/adminSlice"
import productSlice from "./Product/productSlice";
import cartSlice from "./Cart/cartSlice";
import navigationSlice from "./Navigation/navigationSlice";
import storage from "redux-persist/lib/storage"; // default is localStorage
import persistStore from "redux-persist/es/persistStore";
import persistReducer from "redux-persist/es/persistReducer";

const persistConfig = {
  key: 'root', // You can change this key if necessary
  storage,
  whitelist: ['items', 'totalAmount'], // Only persist the cart state
};
const persistedReducer = persistReducer(persistConfig, cartSlice);

const store = configureStore({
  reducer: {
    auth: authSlice,
    products: productSlice,
    cart: persistedReducer,
    navigation: navigationSlice,
    admin: adminSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
export default store;

