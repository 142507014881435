import React from "react";
import { Route, Routes } from "react-router-dom";
import OrderDetails from "./pages/OrderDetails";
import Order from "./components/Order/Order";
import Checkout from "./pages/Checkout";
import ProductOverview from "./pages/ProductOverview";
import RegisterForm from "./Auth/RegisterForm";
import Cart from "./components/Cart/Cart";
import LoginForm from "./Auth/LoginForm";
import Home from "./pages/Home";
import ProductList from "./pages/ProductListPage";
import { FiltersProvider } from "./utils/useFilters";
import NotFound from "./pages/NotFound";
import UserLayout from "./Routers/UserLayout";
import AdminLayout from "./Routers/AdminLayout";
import AdminLogin from "./pages/admin/AdminLoginPage";
import CreateProductsPage from "./pages/admin/products/CreateProductsPage";
import Overview from "./pages/admin/Overview";
import ProductsManagement from "./pages/admin/products/ProductsManagement";
import OrdersManagement from "./pages/admin/orders/OrdersManagement";
import CustomersManagement from "./pages/admin/customers/CustomersManagement";

function App() {
  return (
    // <div className="bg-white">
    //   <Header />
    //   <Routes>
    //     <Route path="products" element={<AdminPage />} />{" "}
    //     {/* Admin's product page */}
    //     <Route path="/" element={<Home />} />
    //     <Route path="/cart" element={<Cart />} />
    //     <Route path="/login" element={<LoginForm />} />
    //     <Route path="/register" element={<RegisterForm />} />
    //     <Route path="/product/:productId" element={<ProductOverview />} />
    //     <Route path="/checkout" element={<Checkout />} />
    //     <Route path="/account/orders" element={<Order />} />
    //     <Route path="/account/order/:orderId" element={<OrderDetails />} />
    //     {/* Dynamic Product Route with Category */}
    //     <Route
    //       path="/products/:category"
    //       element={
    //         <FiltersProvider>
    //           <ProductList />
    //         </FiltersProvider>
    //       }
    //     />
    //     {/* 404 */}
    //     <Route path="*" element={<NotFound />} />
    //   </Routes>
    //   <Footer />
    // </div>
    <div className="bg-[#FBFCF8]">
            <Routes>
        {/* Admin Routes (Protected) */}
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Overview />} />  {/* Admin Dashboard */}
          <Route path="add-product" element={<CreateProductsPage />} /> 
          <Route path="products" element={<ProductsManagement/>}/>
          <Route path="orders" element={<OrdersManagement/>}/>
          <Route path="customers" element={<CustomersManagement/>}/>
        </Route>
        <Route path="/admin/login" element={<AdminLogin />} />

        {/* User Routes */}
        <Route element={<UserLayout />}>
          <Route index element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/product/:productId" element={<ProductOverview />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/account/orders" element={<Order />} />
          <Route path="/account/order/:orderId" element={<OrderDetails />} />

          {/* Dynamic Product Route with Category */}
          <Route
            path="/:section/:category"
            element={
              <FiltersProvider>
                <ProductList />
              </FiltersProvider>
            }
          />
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
