export const color = [
  "white",
  "Black",
  "Blue",
  "Brown",
  "Green",
  "Purple",
  "Red",
  "Yellow",
  "Pink",
];

export const filters = [
  {
    id: "color",
    name: "Color",
    options: [
      { value: "White", label: "White", checked: false },
      { value: "Red", label: "Red", checked: false },
      { value: "beige", label: "Beige", checked: false },
      { value: "blue", label: "Blue", checked: false },
      { value: "brown", label: "Brown", checked: false },
      { value: "green", label: "Green", checked: false },
      { value: "purple", label: "Purple", checked: false },
      { value: "yellow", label: "Yellow", checked: false },
    ],
  },
  {
    id: "sort",
    name: "Sort By",
    options: [
      { value: "newest", label: "Newest Arrivals", checked: false },
      { value: "price_low", label: "Price: Low to High", checked: false },
      { value: "price_high", label: "Price: High to Low", checked: false },
    ],
  }
];
