import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../State/Auth/authSlice";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import Cookies from "js-cookie";

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .max(20, "Password should be of maximum 20 characters length")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
    .matches(/[0-9]/, "Password must contain a number.")
    .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain a special character.")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, user } = useSelector((state) => state.auth);
  const isAuthenticated = Cookies.get("isAuthenticated");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const user = {
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.email,
        password: values.password,
        phoneNumber: values.phoneNumber,
      };

      // Dispatch the registration action
      dispatch(registerUser(user));
    },
  });

  useEffect(() => {
    if (isAuthenticated && user) {
      navigate("/"); // Navigate to home when user is successfully logged in
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Register - Women’s Fashion Store</title>
        <meta
          name="description"
          content="Create an account to shop exclusive women’s fashion collections and track your orders."
        />
        <meta
          name="keywords"
          content="register, women's fashion, create account, track orders"
        />
        <meta property="og:title" content="Register - Women’s Fashion Store" />
        <meta
          property="og:description"
          content="Sign up to create your account and enjoy exclusive offers and easy access to your order history."
        />
        <meta
          property="og:image"
          content="http://example.com/images/register-icon.jpg"
        />
        <link rel="canonical" href="http://example.com/register" />
      </Helmet>
      <div className="py-6">
        <form className="max-w-md mx-auto" onSubmit={formik.handleSubmit}>
          <h1 className="text-xl font-[550] leading-tight tracking-widest text-gray-900 md:text-3xl font-moneta text-center">
            Create an account
          </h1>
          <div className="grid md:grid-cols-2 md:gap-6 mt-4">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-non focus:outline-none focus:ring-1 focus:ring-black peer"
                placeholder=" "
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor="firstName"
                className="font-semibold absolute text-sm font-trade px-3 text-gray-900 duration-300 transform -translate-y-6 scale-75 top-[14px] z-10 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-8 peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[22px]"
              >
                First name
              </label>
              {formik.touched.firstName && formik.errors.firstName ? (
                <Typography color="error">{formik.errors.firstName}</Typography>
              ) : null}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:ring-black peer"
                placeholder=" "
                required
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor="lastName"
                className="font-semibold absolute text-sm font-trade px-3 text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 z-1 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-8 peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[22px]"
              >
                Last name
              </label>
              {formik.touched.lastName && formik.errors.lastName ? (
                <Typography color="error">{formik.errors.lastName}</Typography>
              ) : null}
            </div>
          </div>
          <div className="relative z-0 w-full mb-5 group">
            <input
              type="email"
              name="email"
              id="email"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:ring-black peer"
              placeholder=" "
              required
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label
              htmlFor="email"
              className="font-semibold absolute text-sm font-trade px-3 text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 z-1 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-8 peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[22px]"
            >
              Email address
            </label>
            {formik.touched.email && formik.errors.email ? (
              <Typography color="error">{formik.errors.email}</Typography>
            ) : null}
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="password"
                name="password"
                id="password"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-whit focus:outline-none focus:ring-0 focus:ring-black peer"
                placeholder=" "
                required
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor="password"
                className="font-semibold absolute text-sm font-trade px-3 text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 z-1 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-8 peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[22px]"
              >
                Password
              </label>
              {formik.touched.password && formik.errors.password ? (
                <Typography color="error" fontSize={12}>{formik.errors.password}</Typography>
              ) : null}
              <PasswordStrengthBar password={formik.values.password} />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-whit focus:outline-none focus:ring-0 focus:ring-black peer"
                placeholder=" "
                required
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor="confirmPassword"
                className="font-semibold absolute text-sm font-trade px-3 text-gray-900 duration-300 transform -translate-y-6 scale-75 top-3 z-1 origin-[0] peer-focus:start-2 rtl:peer-focus:translate-x-8 peer-focus:bg-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[22px]"
              >
                Confirm password
              </label>
              {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <Typography color="error" fontSize={12}>{formik.errors.confirmPassword}</Typography>
              ) : null}
            </div>
          </div>

          <div className="flex w-full items-center justify-between pb-2">
            <a
              href="/"
              className="font-medium text-sm px-5 py-2.5 text-center border-[0.5px] border-black w-full basis-[48.5%]"
            >
              Cancel
            </a>
            <button
              type="submit"
              className="text-white bg-black font-medium text-sm px-5 py-2.5 text-center w-full basis-[48.5%]"
            >
              Create an account
            </button>
          </div>
          {error && (
            <Typography color="error" align="center" gutterBottom>
              {error}
            </Typography>
          )}
          <p className="text-sm font-light text-gray-500 py-3">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Login here
            </a>
          </p>
        </form>
      </div>
    </React.Fragment>
  );
}

export default RegisterForm;
