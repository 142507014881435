import { Button, IconButton, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import React from "react";
import "./Cart.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { decreaseQuantity, increaseQuantity, removeFromCart } from "../../State/Cart/cartSlice";
import Cookies from "js-cookie";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items, totalAmount } = useSelector((state) => state.cart);
  const isAuthenticated = Cookies.get("isAuthenticated")

  const handleIncrease = (productId, size) => {
    dispatch(increaseQuantity({ productId, size }));
  };

  const handleDecrease = (productId, size) => {
    dispatch(decreaseQuantity({ productId, size }));
  };

  const handleRemoveItem = (product) => {
    dispatch(removeFromCart(product, { isAuthenticated }));
  };
  const handleCheckout = () => {
    navigate("/checkout?step=2");
  };
  const renderEmptyCart = () => {
    return (
      <div className="px-4 py-6 mx-auto max-w-7xl lg:py-4 md:px-6">
        <div className="font-bembo text-center">
          <h2 className="mb-8 text-4xl">Your Cart is Empty</h2>
          <Button
            href="/"
            variant="contained"
            sx={{
              px: "6rem",
              py: "0.5rem",
              bgcolor: "#000",
              "&:hover": {
                bgcolor: "#000", // Set the same background color for hover, effectively removing the hover effect.
              },
              borderRadius: 0,
              textTransform: "none",
              fontWeight: "400",
            }}
          >
            Continue shopping
          </Button>

          <div className="py-8 text-center font-medium opacity-95">
            <h2 className="text-2xl">Already have an account?</h2>
            <p className="mt-[0.8rem] tracking-wider">
              <a href="/login" className="underline">
                Log in
              </a>{" "}
              to check out faster.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderCart = (items) => {
    return (
      <React.Fragment>
        <Helmet>
          <title>Shopping Cart - Women’s Fashion Store</title>
          <meta
            name="description"
            content="Review your items and checkout from our collection of women’s fashion products including dresses, tops, and more."
          />
          <meta
            name="keywords"
            content="shopping cart, checkout, women's fashion, dresses, tops"
          />
          <meta
            property="og:title"
            content="Shopping Cart - Women’s Fashion Store"
          />
          <meta
            property="og:description"
            content="Finalize your order with the best selection of trendy women’s fashion."
          />
          <meta
            property="og:image"
            content="http://example.com/images/cart-icon.jpg"
          />
          <link rel="canonical" href="http://example.com/cart" />
        </Helmet>
        <div className="px-4 mx-auto max-w-7xl py-6 md:px-6">
          <div className="pb-12 border-b-[0.1rem] border-gray">
          <Typography
            variant="h4"
            gutterBottom
            marginBottom={6}
            fontFamily="moneta"
            fontWeight="500"
            letterSpacing="0.5rem"
          >
            Your Cart
          </Typography>
            <table className="cartItems block w-full md:table">
              <thead>
                <tr>
                  <th
                    className="columnProperties md:w-[70%] text-left"
                    colSpan="3"
                    scope="col"
                  >
                    Product
                  </th>
                  <th className="columnProperties hidden md:block md:text-left">
                    Quantity
                  </th>
                  <th className="columnProperties text-right">Total</th>
                </tr>
              </thead>
              <tbody className="block md:table-row-group">
                {items?.map((product) => (
                  <tr
                    key={product.id}
                    className="grid grid-rows-2 grid-cols-4 md:table-row gap-6 md:gap-0"
                  >
                    <td className="row-span-3 w-[10rem]">
                      <a className="">
                        <img
                          src={product?.imageUrl}
                          className="w-20 h-20 md:w-40 md:h-40 object-contain" // Example dimensions
                          loading="lazy"
                        />
                      </a>
                    </td>

                    <td
                      className="col-span-2 break-words break-all md:w-[35rem]"
                      colSpan="2"
                    >
                      <div className="text-gray-850">{product.title}</div>
                      <div className="text-gray-600">Size: {product.size}</div>
                      <div className="text-gray-600">
                        Color: {product.color.name}
                      </div>
                    </td>
                    <td className="col-span-1 col-start-2 row-start-2">
                      <div className="lg:flex items-center lg:space-x-10">
                        <div className="flex items-center space-x-2">
                          <div className="border flex items-center">
                            <IconButton onClick={() => handleIncrease(product.productId, product.size)}>
                              <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <span className="px-6 opacity-80">{product.quantity}</span>
                            <IconButton onClick={() => handleDecrease(product.productId, product.size)}>
                              <RemoveCircleOutlineOutlinedIcon />
                            </IconButton>
                          </div>
                          <IconButton onClick={() => handleRemoveItem(product)}>
                            <DeleteForeverOutlinedIcon/>
                          </IconButton>
                        </div>
                      </div>
                    </td>
                    <td
                      className="col-span-1 col-start-4 text-right break-words"
                      colSpan="1"
                    >
                      <div className="text-lg font-moneta ">€{product.unitPrice}EUR</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Checkout */}
          <div className="flex justify-end py-12">
            <div className="w-full px-4 lg:w-1/3">
              <div className="flex items-end justify-end pb-4 font-helvetica">
                <h2 className="text-lg px-10 text-gray-800">Total:</h2>
                <p className="text-2xl text-gray-800">{totalAmount}</p>
              </div>
              <div className="text-right pb-4">
                <small className="font-helvetica text-gray-600">
                  FREE WORLDWIDE SHIPPING
                </small>
              </div>
              <Button
                onClick={handleCheckout}
                variant="contained"
                sx={{
                  width: "100%",
                  py: "0.5rem",
                  bgcolor: "#000",
                  "&:hover": {
                    bgcolor: "#000", // Set the same background color for hover, effectively removing the hover effect.
                  },
                  borderRadius: 0,
                }}
              >
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <>{items.length === 0 ? renderEmptyCart() : renderCart(items)}</>
  );
};

export default Cart;
