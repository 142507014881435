import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const calculateTotalAmount = (items) => 
  new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(items.reduce((total, item) => total + item.unitPrice * item.quantity, 0));


/** 🛒 Fetch User Cart (Handles Guest vs Authenticated User) */
export const fetchUserCart = () => async (dispatch, getState) => {
  const { isAuthenticated } = getState().auth;

  if (isAuthenticated) {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/cart`, { withCredentials: true });
      dispatch(setCart(response.data));
    } catch (error) {
      console.error("Error fetching user cart:", error);
    }
  }
};

export const mergeGuestCart = () => async (dispatch, getState) => {
  const items = getState().cart.items; // Now we directly get items from Redux store

  if (items.length > 0) {
    try {
      console.log("merging cart", items);
      const response = await axios.post(
        `${API_BASE_URL}/api/cart/merge`,
        { cartItems: items },
        { withCredentials: true }
      );

      dispatch(setCart(response.data)); // Update cart state with merged items
    } catch (error) {
      console.error("Error merging guest cart:", error);
    }
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalAmount: {},
  },
  reducers: {
    removeFromCart: (state, action) => {
      const { productId, size } = action.payload;
      // Get isAuthenticated from state.auth
      const { isAuthenticated } = action; // Get isAuthenticated from action.meta

      // Sync to backend if authenticated, else sync with cookies
      if (isAuthenticated) {
        axios.delete(`${API_BASE_URL}/api/cart/${productId}`, { data: { size } });
      } else {
        state.items = state.items.filter((item) => !(item.productId === productId && item.size === size));
      }
      state.totalAmount = calculateTotalAmount(state.items);
    },

    addItemToCart: (state, action) => {
      const { productId, size, quantity} = action.payload;
    const existingItem = state.items.find((item) => item.productId === productId && item.size === size);
    
    if (existingItem) {
      existingItem.quantity += quantity;
    } else {
      state.items.push(action.payload);
      
    }
    // Get isAuthenticated from state.auth
    const { isAuthenticated } = action; // Get isAuthenticated from action.meta

    if (isAuthenticated) {
      const response = axios.post(`${API_BASE_URL}/api/cart`, action.payload, { withCredentials: true });
      return response.data;
      }
    },

    clearCart: (state) => {
      state.items = [];
      state.totalAmount = 0;
    },

    setCart: (state, action) => {
      state.items = action.payload;
      state.totalAmount = calculateTotalAmount(state.items);
    },

    increaseQuantity: (state, action) => {
      const { productId, size } = action.payload;
      const existingItem = state.items.find((item) => item.productId === productId && item.size === size);

      if (existingItem) {
        existingItem.quantity += 1;
        state.totalAmount = calculateTotalAmount(state.items);
        const isAuthenticated = action.meta; // Get isAuthenticated from action.meta
        if(isAuthenticated) {
        }
      }
    },

    decreaseQuantity: (state, action) => {
      const { productId, size } = action.payload;
      const existingItem = state.items.find((item) => item.productId === productId && item.size === size);

      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= 1;
        state.totalAmount = calculateTotalAmount(state.items);
      }
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(addItemToCart.fulfilled, (state, action) => {
  //     state.totalAmount = calculateTotalAmount(state.items);
  //     state.items = action.payload
  //   })
  //   .addCase(addItemToCart.rejected, (state, action) => {
  //     console.error("Error adding item to cart:", action.error);
  //   })
  // }
});

/** 🚀 Export Actions & Reducer */
export const {
  removeFromCart,
  clearCart,
  setCart,
  increaseQuantity,
  decreaseQuantity,
  addItemToCart
} = cartSlice.actions;
export default cartSlice.reducer;
