import React from "react";
import { useNavigate } from "react-router-dom";

const SectionProductCard = ({ product }) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/product/${product.id}`, { state: { product } });
    window.scrollTo(0, 0);
  };
  return (
    <div onClick={handleCardClick} className="flex flex-col h-auto w-auto mr-2">
      <div className="h-3/5">
        <img
          className="object-cover object-bottom w-full h-full hover:scale-105 transition duration-500"
          src={product.colors[0].images[0].imageUrl}
          alt=""
        />
      </div>
      <div className="pt-4 font-assistant">
        <h3 className="text-xs sm:text-sm lg:text-lg font-medium text-gray-900">
          {product.title}
        </h3>
        <div className="flex flex-wrap items-center">
          {product.discount && (
            <p className="mt-2 text-sm text-gray-600 line-through mr-5">
              {product.discount}
            </p>
          )}
          <p className="mt-2 text-base font-moneta">€ {(product.price).toFixed(2)}EUR</p>
        </div>
      </div>
    </div>
  );
};

export default SectionProductCard;
