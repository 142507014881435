import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../State/Product/productSlice';

// Custom hook for handling products and filters
const useProducts = (categoryName, filters) => {
  const { colorValues, sortBy } = filters; // Receive filters from useFilters
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { products, totalPages, loading, error, currentPage} = useSelector((state) => state.products)

  const params = useMemo(() => {
    const newParams = new URLSearchParams({
      categoryName,
      page,
      pageSize,
      sort: sortBy,
    });
    
    colorValues.forEach((color) => {
      newParams.append("color", color);
    });
  
    return newParams;
  }, [categoryName, colorValues, page, pageSize, sortBy]);

  // Fetch products on initial render or when any filter changes
  useEffect(() => {
      dispatch(fetchProducts(params))
    }, [params, dispatch]); // Avoid unnecessary updates

  return {
    products,
    loading,
    error,
    totalPages,
    currentPage,
    setPage,
    setPageSize,
  };
};

export default useProducts;
