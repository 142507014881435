import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { mergeGuestCart } from "../Cart/cartSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// 🔹 Login
export const getActiveUsers = createAsyncThunk(
  "auth/getActiveUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user/users`, {
        withCredentials: true, // ✅ Ensures cookies are sent & stored securely
      });
      return response.data; // No need to store JWT manually
    } catch (error) {
      console.log("Error logging in:", error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// 🔹 Login
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userLoginObject, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, userLoginObject, { withCredentials: true });
      if(userLoginObject.loginType === "USER"){
        dispatch(mergeGuestCart()); // Merge guest cart with user cart
      }
      return response.data; // No need to store JWT manually
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// 🔹 Register
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/register`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error);
    }
  }
);

// 🔹 Fetch User Profile
export const getUserProfile = createAsyncThunk(
  "auth/getUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user/me`, {
        withCredentials: true, // ✅ Ensures JWT is sent automatically via cookies
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// 🔹 Logout
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      await axios.post(`${API_BASE_URL}/auth/logout`, {}, { withCredentials: true });
      return null;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

// 🔹 Auth Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    user: null,
    message: null
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    setUser: (state, action) => {
      state.user = action.payload; // Correctly accessing the payload
    }
  },
  extraReducers: (builder) => {
    builder
      // ✅ Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // ✅ Register
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // ✅ Get User Profile
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.error = action.payload;
      })

      // ✅ Logout
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export const { resetError, setUser } = authSlice.actions;
export default authSlice.reducer;
