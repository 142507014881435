import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getNavigationBasedOnUser = createAsyncThunk(
  "navigation/userNavigationView",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user/navigation`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    categories: [],
    pages: []
  },
  extraReducers: (builder) => {
    builder.addCase(getNavigationBasedOnUser.fulfilled, (state, action) => {
      state.categories = action.payload.categories;
      state.pages = action.payload.pages;
    })
    .addCase(getNavigationBasedOnUser.rejected, (state, action) => {
      console.error("Error fetching navigation:", action.error);
    })
  }
});
export default navigationSlice.reducer;
