import React, { useEffect, useState } from "react";
import { FaUser, FaLock, FaEye, FaEyeSlash, FaShieldAlt } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../State/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { Formik, Field, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";

const AdminLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const accessError = useSelector((state) => state.auth.error);
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      const userRole = jwtDecode(token).role;
      if (userRole === "ADMIN") {
        console.log("user is admin");
        navigate("/admin");
      }
    }
  }, [token]);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      const userLoginObject = {
        username: values.username,
        password: values.password,
        loginType: "ADMIN",
      };
      dispatch(loginUser(userLoginObject));
    },
  });
  const { values, errors, touched, handleChange, handleBlur } = formik;

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900">
      <div
        className="absolute inset-0 bg-cover md:bg-center z-0 opacity-10"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1557683316-973673baf926?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2029&q=80')",
        }}></div>

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg p-8 rounded-2xl shadow-2xl w-full max-w-md z-10">
        <div className="text-center mb-8">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            className="w-24 h-24 bg-gradient-to-r from-blue-500 to-purple-600 rounded-full mx-auto mb-4 flex items-center justify-center">
            <FaShieldAlt className="text-white text-4xl" />
          </motion.div>
          <h2 className="text-3xl font-bold text-white mb-2">Admin Login</h2>
          <p className="text-gray-300">Secure access to your dashboard</p>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="mb-6 relative">
            <FaUser className="absolute top-3 left-3 text-gray-400" />
            <input
              type="text"
              name="username"
              placeholder="example@gmail.com"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full pl-10 pr-3 py-2 rounded-lg border-2 bg-gray-800 text-white focus:outline-none focus:border-blue-500 transition duration-300"
            />
            <AnimatePresence>
              {touched.username && errors.username && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 text-xs mt-1">
                  {errors.username}
                </motion.p>
              )}
            </AnimatePresence>
          </div>

          <div className="mb-6 relative">
            <FaLock className="absolute top-3 left-3 text-gray-400" />
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full pl-10 pr-10 py-2 rounded-lg border-2 bg-gray-800 border-gray-700 text-white focus:outline-none focus:border-blue-500 transition duration-300"
            />
            <button
              type="button"
              className="absolute top-3 right-3 text-gray-400 hover:text-gray-200 transition duration-300"
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            <AnimatePresence>
              {touched.password && errors.password && (
                <motion.p
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="text-red-500 text-xs mt-1">
                  {errors.password}
                </motion.p>
              )}
            </AnimatePresence>
          </div>

          <div className="mb-6 text-right">
            <a
              href="#"
              className="text-sm text-blue-400 hover:text-blue-300 transition duration-300">
              Forgot Password?
            </a>
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white py-2 rounded-lg text-lg font-semibold hover:from-blue-700 hover:to-purple-700 transition duration-300 relative overflow-hidden"
            type="submit">
            <span className={`transition-opacity duration-300}`}>Login</span>
          </motion.button>
        </form>
        <AnimatePresence>
          {accessError && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="text-red-500 text-xs mt-1">
              {accessError}
            </motion.p>
          )}
        </AnimatePresence>

        <div className="mt-8 text-center text-sm text-gray-400">
          <a href="#" className="hover:text-gray-200 transition duration-300">
            Main Site
          </a>
          <span className="mx-2">|</span>
          <a href="#" className="hover:text-gray-200 transition duration-300">
            Support
          </a>
          <span className="mx-2">|</span>
          <a href="#" className="hover:text-gray-200 transition duration-300">
            Privacy Policy
          </a>
        </div>
      </motion.div>
    </div>
  );
};

export default AdminLoginPage;
