import { BsPeople } from "react-icons/bs";
import CreateCustomerModal from "./CreateCustomerModal";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../State/admin/adminSlice";

const CustomersManagement = () => {
  const StatCard = ({ title, value, icon: Icon, color, percentage }) => (
    <div className="bg-white dark:bg-gray-800 p-2 md:p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-500 dark:text-gray-400">{title}</p>
          <h3 className="md:text-2xl font-bold mt-2 dark:text-white">{value}</h3>
          {percentage && (
            <p className={percentage > 0 ? "text-green-500 text-sm mt-2" : "text-red-500 text-sm mt-2"}>
              {percentage > 0 ? "+" : ""}{percentage}% from last month
            </p>
          )}
        </div>
        <div className={`p-4 rounded-full ${color} bg-opacity-20`}>
          <Icon className={`w-4 h-4 md:w-6 md:h-6 ${color.replace("bg-", "text-")}`} />
        </div>
      </div>
    </div>
  );
    const customers = [
      {
        id: 1,
        name: "John Doe",
        email: "john@example.com",
        orders: 12,
        spent: 2499.99,
        joined: "2023-01-15"
      },
      {
        id: 2,
        name: "Jane Smith",
        email: "jane@example.com",
        orders: 8,
        spent: 1899.99,
        joined: "2023-02-20"
      },
      {
        id: 3,
        name: "Mike Johnson",
        email: "mike@example.com",
        orders: 15,
        spent: 3299.99,
        joined: "2023-03-10"
      }
    ];
    const dispatch = useDispatch();
    const users = useSelector((state) => state.admin.users, shallowEqual); // Prevents unnecessary renders
    console.log(users)
    useEffect(() => {
      if (users.length === 0) { // ✅ Prevents infinite loop
        dispatch(getAllUsers());
      }
    }, [dispatch, users]); 

    const [isModalOpen, setModalOpen] = useState(false);
    const closeModal = () => {
      setModalOpen(false);
    };
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="p-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold dark:text-white">Customers</h1>
            <div className="flex flex-wrap gap-4">
              <input
                type="text"
                placeholder="Search customers..."
                className="px-4 py-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:text-white"
              />
              <button onClick={() => setModalOpen(true)} className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
                Add Customer
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            <StatCard title="Total Customers" value={users.length} icon={BsPeople} color="bg-blue-500" percentage={8} />
            <StatCard title="Active Customers" value="45" icon={BsPeople} color="bg-green-500" percentage={12} />
            <StatCard title="New This Month" value="15" icon={BsPeople} color="bg-purple-500" percentage={5} />
          </div>
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
            <table className="w-full">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Orders</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Total Spent</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Joined</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {customers.map((customer) => (
                  <tr key={customer.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{customer.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{customer.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{customer.orders}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">${customer.spent}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{customer.joined}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300 mr-4">Edit</button>
                      <button className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <CreateCustomerModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    );
  };

export default CustomersManagement