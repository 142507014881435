import { Outlet } from "react-router-dom";
import Footer from "../pages/Footer";
import Header from "../pages/Header";

const UserLayout = () => {
  return (
    <>
      <Header />
      <main className="container mx-auto">
        <Outlet/>
      </main>
      <Footer />
    </>
  );
};

export default UserLayout;
