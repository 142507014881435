import React from "react";
import SectionCarousel from "../components/Home/SectionCarousel";
import Carousel from "../components/Home/Carousel";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <React.Fragment>
      <Helmet>
        <title>Women’s Fashion Store - Trendy Clothes & Accessories</title>
        <meta
          name="description"
          content="Shop the latest women's fashion trends including dresses, tops, jeans, and accessories. Free shipping on orders over $50."
        />
        <meta
          name="keywords"
          content="women's fashion, trendy clothes, dresses, accessories, free shipping, fashion store"
        />
        <meta
          property="og:title"
          content="Women’s Fashion Store - Trendy Clothes & Accessories"
        />
        <meta
          property="og:description"
          content="Explore the newest trends in women’s fashion. Shop dresses, tops, jeans, and more with free shipping options."
        />
        <meta
          property="og:image"
          content="http://argjentinabajraktari.com/images/home-banner.jpg?height=800&width=1200"
        />
        <link rel="canonical" href="http://argjentinabajraktari.com/" />
      </Helmet>

      <Carousel />
      <div className="2xl:px-[10rem] xl:px-[2rem] lg:px-[1rem]">
        <SectionCarousel sectionName={"Evening Dresses"} categoryName={"evening-dresses"}/>
      </div>
    </React.Fragment>
  );
}

export default Home;
