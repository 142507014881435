import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Remove } from "@mui/icons-material";

const ProductSizeModal = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <div className="relative bg-warmWhite p-10 rounded-xl shadow-lg font-moneta">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <Remove className="h-6 w-6" />
        </button>

        {/* Modal Title */}
        <DialogTitle className="text-4xl font-semibold text-center text-gray-900 mb-8">
          Women's Size Guide
        </DialogTitle>

        {/* Modal Content */}
        <DialogContent>
          {/* Women's Size Chart */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Women's Size Chart</h2>
            <p className="text-lg text-gray-600 mb-6">
              Find your perfect fit with our size guide. Measurements are in inches.
            </p>
            <table className="w-full text-left table-auto border-collapse border border-gray-300 mt-4 font-semibold">
              <thead className="bg-gray-100">
                <tr>
                  <th className="border-b px-6 py-3 text-sm text-gray-600">Size</th>
                  <th className="border-b px-6 py-3 text-sm text-gray-600">Bust (inches)</th>
                  <th className="border-b px-6 py-3 text-sm text-gray-600">Waist (inches)</th>
                  <th className="border-b px-6 py-3 text-sm text-gray-600">Hips (inches)</th>
                </tr>
              </thead>
              <tbody>
                <tr className="hover:bg-gray-50">
                  <td className="border-b px-6 py-4 text-sm text-gray-900">XXS</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">28-30</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">22-24</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">32-34</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="border-b px-6 py-4 text-sm text-gray-900">XS</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">30-32</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">24-26</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">34-36</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="border-b px-6 py-4 text-sm text-gray-900">S</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">34-36</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">28-30</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">38-40</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="border-b px-6 py-4 text-sm text-gray-900">M</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">38-40</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">32-34</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">42-44</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="border-b px-6 py-4 text-sm text-gray-900">L</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">42-44</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">36-38</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">46-48</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="border-b px-6 py-4 text-sm text-gray-900">XL</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">46-48</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">40-42</td>
                  <td className="border-b px-6 py-4 text-sm text-gray-900">50-52</td>
                </tr>
              </tbody>
            </table>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ProductSizeModal;
