import { useState } from "react";
import { BsCart3, BsSearch } from "react-icons/bs";

const OrdersManagement = () => {
    const orders = [
      {
        id: "#ORD-001",
        customer: "John Doe",
        date: "2023-08-15",
        total: 599.99,
        status: "completed",
        items: [
          { name: "Designer Dress", quantity: 1, price: 399.99, image: "https://images.unsplash.com/photo-1539008835657-9e8e9680c956" },
          { name: "Premium Jacket", quantity: 1, price: 200.00, image: "https://images.unsplash.com/photo-1551028719-00167b16eac5" }
        ],
        shipping: "Express Delivery",
        payment: "Credit Card"
      },
      {
        id: "#ORD-002",
        customer: "Jane Smith",
        date: "2023-08-14",
        total: 299.99,
        status: "pending",
        items: [
          { name: "Leather Handbag", quantity: 1, price: 299.99, image: "https://images.unsplash.com/photo-1584917865442-de89df76afd3" }
        ],
        shipping: "Standard Delivery",
        payment: "PayPal"
      },
      {
        id: "#ORD-003",
        customer: "Mike Johnson",
        date: "2023-08-13",
        total: 799.99,
        status: "processing",
        items: [
          { name: "Designer Dress", quantity: 2, price: 799.99, image: "https://images.unsplash.com/photo-1539008835657-9e8e9680c956" }
        ],
        shipping: "Next Day Delivery",
        payment: "Apple Pay"
      }
    ];
    const StatCard = ({ title, value, icon: Icon, color, percentage }) => (
      <div className="bg-white dark:bg-gray-800 p-2 md:p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm text-gray-500 dark:text-gray-400">{title}</p>
            <h3 className="md:text-2xl font-bold mt-2 dark:text-white">{value}</h3>
            {percentage && (
              <p className={percentage > 0 ? "text-green-500 text-sm mt-2" : "text-red-500 text-sm mt-2"}>
                {percentage > 0 ? "+" : ""}{percentage}% from last month
              </p>
            )}
          </div>
          <div className={`p-4 rounded-full ${color} bg-opacity-20`}>
            <Icon className={`w-4 h-4 md:w-6 md:h-6 ${color.replace("bg-", "text-")}`} />
          </div>
        </div>
      </div>
    );
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [filterStatus, setFilterStatus] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
  
    const getStatusColor = (status) => {
      const colors = {
        completed: "bg-green-100 text-green-800 border-green-200",
        pending: "bg-yellow-100 text-yellow-800 border-yellow-200",
        processing: "bg-blue-100 text-blue-800 border-blue-200",
        cancelled: "bg-red-100 text-red-800 border-red-200"
      };
      return colors[status] || "bg-gray-100 text-gray-800 border-gray-200";
    };
  
    const filteredOrders = orders.filter(order => {
      const matchesStatus = filterStatus === "all" || order.status === filterStatus;
      const matchesSearch = order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
                           order.customer.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <div className="p-8">
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-2xl font-bold dark:text-white">Fashion Orders Management</h1>
                <p className="text-gray-600 dark:text-gray-400 mt-1">Track and manage your fashion store orders</p>
              </div>
              <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2">
                <BsCart3 className="w-4 h-4" />
                Create Order
              </button>
            </div>
  
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <StatCard title="Total Orders" value={orders.length} icon={BsCart3} color="bg-blue-500" percentage={8} />
              <StatCard title="Completed" value={orders.filter(o => o.status === "completed").length} icon={BsCart3} color="bg-green-500" percentage={12} />
              <StatCard title="Pending" value={orders.filter(o => o.status === "pending").length} icon={BsCart3} color="bg-yellow-500" percentage={-5} />
              <StatCard title="Processing" value={orders.filter(o => o.status === "processing").length} icon={BsCart3} color="bg-blue-500" percentage={3} />
            </div>
  
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
              <div className="flex flex-col md:flex-row gap-4 mb-6">
                <div className="flex-1">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search orders by ID or customer..."
                      className="w-full px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600 dark:text-white pl-10"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <BsSearch className="absolute left-3 top-3 text-gray-400" />
                  </div>
                </div>
                <select
                  className="px-4 py-2 rounded-lg border dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <option value="all">All Status</option>
                  <option value="completed">Completed</option>
                  <option value="pending">Pending</option>
                  <option value="processing">Processing</option>
                </select>
              </div>
  
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Order Details</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Customer</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Items</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Status</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {filteredOrders.map((order) => (
                      <tr key={order.id} className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                        <td className="px-6 py-4">
                          <div className="flex flex-col">
                            <span className="font-medium dark:text-white">{order.id}</span>
                            <span className="text-sm text-gray-500">{order.date}</span>
                            <span className="text-sm font-medium text-blue-600">${order.total}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex flex-col">
                            <span className="font-medium dark:text-white">{order.customer}</span>
                            <span className="text-sm text-gray-500">{order.shipping}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex -space-x-4">
                            {order.items.map((item, idx) => (
                              <img
                                key={idx}
                                src={item.image}
                                alt={item.name}
                                className="w-10 h-10 rounded-full border-2 border-white dark:border-gray-800 object-cover"
                                title={`${item.name} - $${item.price}`}
                              />
                            ))}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <span className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(order.status)}`}>
                            {order.status}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex gap-2">
                            <button 
                              onClick={() => setSelectedOrder(order)}
                              className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                            >
                              View Details
                            </button>
                            <button className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300">
                              Edit
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
  
              <div className="mt-6 flex justify-between items-center">
                <p className="text-gray-600 dark:text-gray-400">
                  Showing {filteredOrders.length} of {orders.length} orders
                </p>
                <div className="flex gap-2">
                  <button className="px-4 py-2 border rounded-lg dark:border-gray-600 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700">
                    Previous
                  </button>
                  <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {selectedOrder && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg max-w-2xl w-full p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold dark:text-white">Order Details</h2>
                <button 
                  onClick={() => setSelectedOrder(null)}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  ×
                </button>
              </div>
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Order ID</p>
                    <p className="font-medium dark:text-white">{selectedOrder.id}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Customer</p>
                    <p className="font-medium dark:text-white">{selectedOrder.customer}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Date</p>
                    <p className="font-medium dark:text-white">{selectedOrder.date}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Total</p>
                    <p className="font-medium dark:text-white">${selectedOrder.total}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-gray-500 mb-2">Items</p>
                  <div className="space-y-2">
                    {selectedOrder.items.map((item, idx) => (
                      <div key={idx} className="flex items-center gap-4 p-2 bg-gray-50 dark:bg-gray-700 rounded-lg">
                        <img src={item.image} alt={item.name} className="w-12 h-12 rounded-lg object-cover" />
                        <div>
                          <p className="font-medium dark:text-white">{item.name}</p>
                          <p className="text-sm text-gray-500">Qty: {item.quantity} × ${item.price}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

export default OrdersManagement;