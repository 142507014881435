import React, { useState, useEffect } from "react";
import { FiMinus, FiPlus, FiShoppingCart } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductById } from "../State/Product/productSlice";
import { Button } from "@mui/material";
import SectionCarousel from "../components/Home/SectionCarousel";
import ProductSizeModal from "../components/Product/ProductSizeModal";
import { addItemToCart } from "../State/Cart/cartSlice";
import Cookies from "js-cookie";

const ProductOverview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { productId } = useParams();
    const { product } = useSelector((state) => state.products)
    const [selectedImage, setSelectedImage] = useState(0);
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [selectedColor, setSelectedColor] = useState(new URLSearchParams(window.location.search).get("color") || "");
    const [selectedSize, setSelectedSize] = useState(new URLSearchParams(window.location.search).get("size") || "M" );
    const [isZoomed, setIsZoomed] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const sizes = ["XXS", "XS", "S", "M", "L", "XL"];

    useEffect(() => {
        dispatch(getProductById(productId));
      }, [dispatch, productId]);

    const [isProductSizeModalOpen, setIsProductSizeModalOpen] = useState(false);
    const openSizeModal = () => setIsProductSizeModalOpen(true);
    const closeSizeModal = () => setIsProductSizeModalOpen(false);

    const handleAddToCart = () => {
      const item = {
        productId: product.id,
        title: product.title,
        color: product.colors[selectedColorIndex],
        size: selectedSize,
        imageUrl: product.colors[selectedColorIndex]?.images[0]?.imageUrl,
        unitPrice: product.price,
        quantity: quantity,
      };
      const isAuthenticated = Cookies.get("isAuthenticated")
  
      dispatch(addItemToCart(item, { isAuthenticated }));
      navigate("/cart");
    };

  const handleQuantityChange = (action) => {
    if (action === "increment") {
      setQuantity(quantity + 1);
    } else if (action === "decrement" && quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const ImageGallery = ({ images }) => {
    return(  
        <div className="relative w-full">
      <div 
        className="relative overflow-hidden aspect-square rounded-lg"
        onMouseEnter={() => setIsZoomed(true)}
        onMouseLeave={() => setIsZoomed(false)}
      >
        <img
          src={images[selectedImage]?.imageUrl}
          alt={`Product view ${selectedImage + 1}`}
          className={`w-full h-full object-cover transition-transform duration-300 ${isZoomed ? "scale-125" : "scale-100"}`}
        />
      </div>
      <div className="mt-4 grid grid-cols-4 gap-2">
        {images.map((image, index) => (
          <button
            key={index}
            onClick={() => setSelectedImage(index)}
            className={`aspect-square rounded-lg overflow-hidden ${selectedImage === index ? "ring-2 ring-blue-500" : ""}`}
          >
            <img src={image.imageUrl} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-cover" />
          </button>
        ))}
      </div>
    </div>
    );
  }
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {product?.colors?.length > 0 && (
        <ImageGallery images={product.colors[selectedColorIndex]?.images ?? []} />
        )}

        <div className="space-y-6">
        <h2 className="sr-only">Product information</h2>
          <h1 className="text-lg lg:text-2xl font-medium font-moneta pr-10">{product.title}</h1>
          
          <div className="flex items-center space-x-4 font-moneta">
          <p className="font-semibold">€ {(product?.price ?? 0).toFixed(2)} EUR</p>

            {product.discount && (
              <span className="px-2 py-1 bg-red-100 text-red-800 rounded-md text-sm font-medium">
                {product.discount}% OFF
              </span>
            )}
          </div>

          <p className="text-gray-600">{product.description}</p>

          <div className="space-y-4">
            <div>
            <h3 className="font-moneta font-semibold">Color</h3>
              <div className="mt-2 flex space-x-2">
                {product?.colors?.map((color, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedColor(color);
                      setSelectedColorIndex(index)
                      const params = new URLSearchParams(window.location.search);
                      params.set('color', color.name);
                      window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
                    }}
                    className={`w-6 h-6 ${selectedColor.name === color.name ? `ring-1 ring-offset-2 ring-black ${selectedColor.className}` : `${color.className}`}`}
                  />
                ))}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                    <h3 className="font-moneta font-semibold">Size</h3>
                    <button
                      onClick={openSizeModal}
                      className="text-sm font-semibold font-moneta hover:text-gray-600"
                    >
                      Size guide
                    </button>
                  </div>
              <div className="mt-2 grid grid-cols-4 gap-2">
                {sizes.map((size) => (
                  <button
                    key={size}
                    onClick={() => {
                        setSelectedSize(size)
                        const params = new URLSearchParams(window.location.search);
                        params.set('size', size);
                        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
                    }}
                    className={`py-2 text-sm font-medium ${selectedSize === size
                      ? "ring-1 ring-black"
                      : "border-gray-300 border hover:ring-1 "}`}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            <React.Fragment>
            <h3 className="text-sm font-medium text-gray-900">Quantity:</h3>
            <div className="flex items-center justify-between">
              <button
                onClick={() => handleQuantityChange("decrement")}
                className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
              >
                <FiMinus className="w-5 h-5" />
              </button>
              <span className="px-4 py-2 text-lg font-medium text-gray-900">{quantity}</span>
              <button
                onClick={() => handleQuantityChange("increment")}
                className="p-2 text-gray-500 hover:text-gray-700 transition-colors"
              >
                <FiPlus className="w-5 h-5" />
              </button>
            </div>
            </React.Fragment>
          </div>

          <div className="flex items-center justify-between">
            <Button
                variant="contained"
                onClick={handleAddToCart}
                style={{ width: "100%",marginTop: "15px", backgroundColor: "black"}}
                >
                <FiShoppingCart className="w-5 h-5 mr-2" />
                Add to Cart
                </Button>
          </div>

        </div>
      </div>

        <SectionCarousel categoryName={product.categoryName} sectionName={"You may also like"} />

        {/* Pass modal state to ProductSizeModal */}
        <ProductSizeModal isOpen={isProductSizeModalOpen} onClose={closeSizeModal} />
    </div>
  );
};

export default ProductOverview;